import React, { useEffect, useState } from "react";
import { Input, Row } from "reactstrap";
import { FaExpandArrowsAlt, FaCompressArrowsAlt } from "react-icons/fa";
import {
  DataSet,
  IdType,
  Network,
} from "vis-network/standalone/esm/vis-network";
import ListaProductosRed from "./ListaProductosRed";
import ListaClienteRed from "./ListaClienteRed";

interface ProductClientRelation {
  productCode: string;
  productName: string;
  clientName: string;
  clientId: string;
  totalSales: number;
}
interface SumaTotalPorProducto {
  productCode: string;
  productName: string;
  totalSales: number;
}

interface ProductClientNetworkProps {
  data: ProductClientRelation[];
  sumasTotalesPorProducto: SumaTotalPorProducto[];
}

const ProductClientNetwork: React.FC<ProductClientNetworkProps> = ({
  data,
  sumasTotalesPorProducto,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [network, setNetwork] = useState<Network | null>(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [searchTermCliente, setSearchTermCliente] = useState("");

  const [isFullScreen, setIsFullScreen] = useState(false);

  //const [topProducts, setTopProducts] = useState<sumasTotalesPorProducto[]>([]);

  const toggleFullScreen = () => {
    const elem = document.getElementById("network-container");
    if (!document.fullscreenElement && elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };
  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    const nodes = new DataSet();
    const edges = new DataSet();

    const uniqueClientIds = new Set(
      data.map((item) => `client-${item.clientName}`)
    );

    const ventasPorProductoMap = sumasTotalesPorProducto.reduce(
      (acc, producto) => {
        //@ts-ignore
        acc[producto.productCode] = producto.totalSales;
        return acc;
      },
      {}
    );

    uniqueClientIds.forEach((clientId) => {
      if (!nodes.get(clientId)) {
        nodes.add({
          id: clientId,
          label: clientId.replace("client-", ""),
          //label: uniqueClientIds.replace("client-", ""),

          color: "#97C2FC",
          shape: "box",
          margin: { top: 5, bottom: 5, left: 5, right: 5 }, // Margen alrededor del texto
          font: { size: 8 }, // Tamaño de la fuente más pequeño para reducir el tamaño de la caja
        });
      }
    });
    /*
    uniqueClientIds.forEach((uniqueClientId) => {
      if (!nodes.get(uniqueClientId)) {
        nodes.add({
          id: uniqueClientId,
          //label: uniqueClientId.replace("client-", ""),
          label: uniqueClientId,
          // Resto de las propiedades del nodo...
          color: "#97C2FC",
          shape: "box",
          margin: { top: 5, bottom: 5, left: 5, right: 5 }, // Margen alrededor del texto
          font: { size: 8 }, // Tamaño de la fuente más pequeño para reducir el tamaño de la caja
        });
      }
    }); */

    data.forEach((item) => {
      const productNodeId = item.productCode;
      const clientNodeId = `client-${item.clientName}`;
      //const clientNodeId = item.clientId;
      //@ts-ignore
      const totalSales = ventasPorProductoMap[productNodeId] || item.totalSales; // Usa el valor de sumasTotalesPorProducto si está disponible

      // Ensure the product node is unique before adding
      if (!nodes.get(productNodeId)) {
        nodes.add({
          id: productNodeId,
          //label: `${item.productName}\nVentas: ${item.totalSales}`,
          label: `${item.productName}`,
          title: `${item.productName}\nVentas: ${totalSales}`,
          value: totalSales,
          shape: "dot",
          color: "#7BE141",
        });
      }

      // Asegurarse de que el nodo del cliente es único antes de agregar
      if (!nodes.get(clientNodeId)) {
        nodes.add({
          id: clientNodeId, // Ahora el ID del nodo es el clientId
          label: item.clientName,
          shape: "box", // Puedes elegir la forma que mejor represente a los clientes
          color: "#FFC107", // Un color distinto para diferenciarlo de los nodos de producto
        });
      }

      // Only add edge if it does not exist
      const edgeId = `${productNodeId}-${clientNodeId}`;
      if (!edges.get(edgeId)) {
        edges.add({
          id: edgeId,
          from: productNodeId,
          to: clientNodeId,
        });
      }
    });

    const container = document.getElementById("mynetwork");
    const networkData = { nodes, edges };
    const options = {
      layout: {
        improvedLayout: false,
      },
      // physics: {
      //   enabled: true,
      //   stabilization: {
      //     iterations: 300,
      //   },
      // },
      physics: false,
    };

    if (container) {
      const tempOptions = {
        ...options,
        physics: {
          enabled: true,
          stabilization: { iterations: 300 },
        },
      };
      const network = new Network(container, networkData, tempOptions);
      setNetwork(network);
      setIsLoading(true);
      network.on("selectNode", (params) => {
        setIsLoading(true);

        // Utiliza setTimeout para permitir que la interfaz de usuario se actualice con el estado de carga
        setTimeout(() => {
          console.log("actualizando...");
          const selectedNodeId = params.nodes[0];

          // Obtener todos los nodos y aristas conectados al nodo seleccionado
          const connectedNodes = network.getConnectedNodes(selectedNodeId);
          const connectedEdges = network.getConnectedEdges(selectedNodeId);

          // Actualizar el estilo de todos los nodos a "débil" primero
          nodes.update(
            [...nodes.get()].map((node) => ({
              id: node.id,
              color: {
                background: "rgba(211, 211, 211, 0.5)",
                border: "rgba(211, 211, 211, 0.5)",
                font: { color: "rgba(211, 211, 211, 0.1)" }, // Hace que el color del label sea tenue
              },
              borderWidth: 1,
            }))
          );

          // Y luego actualizar el estilo de los nodos conectados a "resaltado"
          connectedNodes.forEach((nodeId) => {
            nodes.update({
              id: nodeId,
              color: { background: "#97C2FC", border: "#2B7CE9" },
              borderWidth: 2,
            });
          });

          // No olvides resaltar también el nodo actualmente seleccionado
          nodes.update({
            id: selectedNodeId,
            color: { background: "lightblue", border: "blue" },
            borderWidth: 3,
          });

          // Actualizar el estilo de todas las aristas a "débil"
          edges.update(
            [...edges.get()].map((edge) => ({
              id: edge.id,
              color: "rgba(211, 211, 211, 0.5)",
              width: 1,
            }))
          );

          // Y luego resaltar las aristas conectadas
          connectedEdges.forEach((edgeId) => {
            edges.update({ id: edgeId, color: "#71D303", width: 1 });
          });
          console.log("carga terminada");

          // Finalmente, oculta el loader
          setIsLoading(false);
        }, 0); // El retraso de 0ms aquí es intencionado para permitir que el cambio de estado de isLoading se refleje en la UI
      });

      network.on("deselectNode", () => {
        // Resetear estilos de nodos a su estado original
        nodes.update(
          [...nodes.get()].map((node) => ({
            id: node.id,
            color: {
              background: "rgba(211, 211, 211, 0.5)",
              border: "rgba(211, 211, 211, 0.5)",
            },
            borderWidth: 1,
          }))
        );

        // Resetear estilos de aristas a su estado original
        edges.update(
          [...edges.get()].map((edge) => ({
            id: edge.id,
            color: "rgba(211, 211, 211, 0.5)",
            width: 1,
          }))
        );
      });

      network.once("stabilizationIterationsDone", () => {
        network.setOptions({ physics: false });
        network.fit();
        setIsLoading(false);
      });
    }

    //setTopProducts(sortedProducts);
  }, [data, sumasTotalesPorProducto]);

  useEffect(() => {
    if (searchTerm.trim() !== "" && network) {
      // Buscar coincidencias de nombre de producto o cliente en los datos
      const foundItem = data.find(
        (item) =>
          item.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.clientName.toLowerCase().includes(searchTerm.toLowerCase())
      );

      if (foundItem) {
        // Seleccionar el nodo basado en productCode o clientId
        network.selectNodes([foundItem.productCode]);
      } else {
        network.unselectAll();
      }
    }
  }, [searchTerm, network, data]);

  useEffect(() => {
    if (searchTermCliente.trim() !== "" && network) {
      const foundItem = data.find(
        (item) => item.clientId === searchTermCliente
      );

      if (foundItem) {
        console.log("Cliente encontrado:", foundItem.clientName);
        network.selectNodes([foundItem.clientId]);
      } else {
        console.log("Cliente no encontrado");
        network.unselectAll();
      }
    }
  }, [searchTermCliente, network, data]);

  const handleProductoSeleccionado = (producto: SumaTotalPorProducto) => {
    console.log("Producto seleccionado:", producto);
    setSearchTerm(producto.productName);
    // Aquí puedes manejar la selección, por ejemplo, actualizar el estado o realizar otras acciones
  };

  const manejarClienteSeleccionado = (clientId: string) => {
    console.log("Cliente seleccionado:", clientId);
    setSearchTermCliente(clientId);
    // Realiza acciones adicionales aquí según sea necesario
  };

  return (
    <div
      id="network-container"
      style={{ position: "relative", height: "800px", width: "100%" }}
    >
      <div id="mynetwork"></div>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000, // Asegúrate de que sea suficientemente alto
          }}
        >
          <div className="spinner"></div>
        </div>
      )}
      <div
        style={{
          position: "absolute",
          right: "10px",
          top: "10px",
          zIndex: 20,
          display: "flex",
          flexDirection: "column", // Cambiado a columna para alinear verticalmente
          alignItems: "flex-start", // Alinea elementos al inicio (izquierda)
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <button
            onClick={toggleFullScreen}
            style={{
              position: "absolute",
              right: "10px",
              top: "2px",
              zIndex: 20,
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
          >
            {isFullScreen ? <FaCompressArrowsAlt /> : <FaExpandArrowsAlt />}
          </button>
        </div>
        <ListaProductosRed
          productos={sumasTotalesPorProducto}
          onProductoSeleccionado={handleProductoSeleccionado}
        />
        <div style={{ position: "relative", height: "100%", width: "100%" }}>
          {/* Otros componentes o contenido aquí */}
          {/* <ListaClienteRed
            clientes={data}
            onClienteSeleccionado={manejarClienteSeleccionado}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default ProductClientNetwork;
