import React, { useState } from "react";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

import "./Map.scss";

import MapPopUp from "./MapPopUp";

const redIcon = L.icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
});

const colorMap: { [key: string]: string } = {
  "#C70138":
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
  "#229954":
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png",
  "#FFC300":
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-yellow.png",
  "#9104FA":
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-violet.png",
};

const createIcon = (color: string) => {
  return L.icon({
    iconUrl: colorMap[color] || colorMap["#C70138"], // Usa rojo por defecto si no coincide
    iconSize: [25, 41],
    iconAnchor: [12.5, 41],
    popupAnchor: [0, -41],
  });
};

const MapLegend2 = () => {
  return (
    <div className="map-legend-canal">
      <h4>Ultima Gestión</h4>
      <ul>
        <li>
          <span
            className="legend-icon-canal"
            style={{ backgroundColor: "#C70138" }}
          ></span>
          Mayor a 6 Meses o sin Actividad
        </li>
        <li>
          <span
            className="legend-icon-canal"
            style={{ backgroundColor: "#FFC300" }}
          ></span>
          de 31 Días a 6 Meses
        </li>
        <li>
          <span
            className="legend-icon-canal"
            style={{ backgroundColor: "#229954" }}
          ></span>
          de 0 a 30 Días
        </li>
        <li>
          <span
            className="legend-icon-canal"
            style={{ backgroundColor: "#9104FA" }}
          ></span>
          Gestión Programada
        </li>
      </ul>
    </div>
  );
};

const Map = (props: any) => {
  const [position] = useState({
    lat: 39.707186656826565,
    lng: -4.746093750000001,
  });

  const [inputlat, setinputlat] = useState<undefined>(undefined);
  const [inputlng, setinputlng] = useState<undefined>(undefined);

  const dragend = (e: any) => {
    console.log(e.target.getLatLng());
    setinputlat(e.target.getLatLng().lat);
    setinputlng(e.target.getLatLng().lng);
  };

  return (
    <div>
      <MapContainer className="mapDetails" center={position} zoom={6}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <Marker
          position={position}
          // eslint-disable-next-line no-eval
          icon={redIcon}
          key={0}
          draggable={true}
          eventHandlers={{
            dragend: dragend,
          }}
        ></Marker>
        {props.customers.map(
          (items: {
            latitud: any;
            longitud: any;
            id: any;
            name: any;
            description: any;
            address: any;
            country: any;
            CustomerColorReport: string;
            comercial: string;
            nextVisit: any;
            diffDays: any;
          }) => (
            <Marker
              //position={[items.latitud, items.longitud]}
              position={[
                parseFloat(items.latitud?.toString().replace(",", ".")) ||
                  position.lat,
                parseFloat(items.longitud?.toString().replace(",", ".")) ||
                  position.lng,
              ]}
              // eslint-disable-next-line no-eval
              icon={createIcon(items.CustomerColorReport)}
              key={items.id}
              draggable={false}
            >
              <Popup>
                <MapPopUp
                  show={props.show}
                  name={items.name}
                  description={items.description}
                  id={items.id}
                  address={items.address}
                  country={items.country}
                  CustomerColorReport={items.CustomerColorReport}
                  comercial={items.comercial}
                  nextVisit={items.nextVisit}
                  diffDays={items.diffDays}
                />
              </Popup>
            </Marker>
          )
        )}
        <MapLegend2 />
      </MapContainer>
      <label>{inputlat ? inputlat + "," + inputlng : null}</label>
    </div>
  );
};

export default Map;
