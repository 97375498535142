import React, { useState, useMemo } from "react";

interface Producto {
  productCode: string;
  productName: string;
  totalSales: number;
}

interface ListaProductosProps {
  productos: Producto[];
  onProductoSeleccionado: (producto: Producto) => void; // Función para manejar la selección de un producto
}

const ListaProductosRed: React.FC<ListaProductosProps> = ({
  productos,
  onProductoSeleccionado,
}) => {
  const [terminoBusqueda, setTerminoBusqueda] = useState("");

  const productosFiltrados = useMemo(() => {
    const termino = terminoBusqueda.toLowerCase();
    return productos
      .filter((producto) =>
        producto.productName.toLowerCase().includes(termino)
      )
      .sort((a, b) => b.totalSales - a.totalSales)
      .slice(0, 11); // Limita los resultados a 10
  }, [productos, terminoBusqueda]);

  // Aplicando estilos previamente discutidos
  const estilos = {
    lista: {
      listStyle: "none",
      padding: 0,
      maxHeight: "410px",
      overflowY: "auto" as const, // Uso de 'as const' para asegurar el tipo correcto
      marginTop: "10px",
      backgroundColor: "rgba(254, 249, 231, 0.8)",
      borderRadius: "8px",
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      maxWidth: "300px", // Ancho máximo más pequeño
      fontSize: "0.8rem", // Tamaño de letra más pequeño
    },
    elemento: {
      padding: "10px",
      borderBottom: "1px solid #ddd",
      cursor: "pointer",
      transition: "background-color 0.3s",
    },
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Buscar producto..."
        value={terminoBusqueda}
        onChange={(e) => setTerminoBusqueda(e.target.value)}
        style={{ marginBottom: "10px", width: "100%" }}
      />
      <ul style={estilos.lista}>
        {productosFiltrados.map((producto) => (
          <li
            key={producto.productCode}
            style={estilos.elemento}
            onClick={() => onProductoSeleccionado(producto)}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor = "#eaeaea")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor = "transparent")
            }
          >
            {producto.productName} - Ventas: € {producto.totalSales.toFixed(2)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListaProductosRed;
