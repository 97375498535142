import axios from "axios";
import config from "./confserver.json";



export const getRegionsChart = async (country:any,type:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    country: country,
    type:type,
    query:"chartRegions"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const gettotalProductOffer = async () => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    query:"chartOffers"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const gettotalProductOfferByStatus = async (id:number) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    idstatus:id,
    query:"chartOffersByStatus"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const getProductOfferPieByUser = async (id:number,idctp:number) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    status: id,
    idctp: idctp,
    query:"getProductOfferPieByUser"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const getProductOfferDetailPieByUser = async (codigouser:any, statusOffer:any,producto:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    status: statusOffer,
    idctp: codigouser,
    producto: producto,
    query:"getProductOfferDetailPieByUser"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const getCustomersOfferPieByProduct = async (id:number,idproduct:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    status: id,
    idproduct: idproduct,
    query:"getCustomersOfferPieByProduct"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const getCustomersOfferPieByProductDetail = async (id:number,idproduct:any,reference_name:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    status: id,
    idproduct: idproduct,
    reference_name:reference_name,
    query:"getCustomersOfferPieByProductDetail"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const gettotalOffers = async () => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    query:"gettotalOffers"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const gettotalOffersBystatus = async (id:number) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    status:id,
    query:"gettotalOffersByStatus"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const getBillingData = async (id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    customerid:id,
    query:"getBilling"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const getBillingTotal = async () => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    query:"getBillingTotal"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const getCustomersFacturation1 = async () => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    query:"getCustomersFacturation1"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const getCustomersFacturation2 = async () => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    query:"getCustomersFacturation2"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}


export const getFacturationByProducts = async () => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    query:"getFacturationByProducts"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const getCantidadByProducts = async () => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    query:"getCantidadByProducts"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}


export const getFacturationChannelsPie = async (year:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    year:year,
    query:"getFacturationChannelsPie"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}


export const getSalesByCategory = async () => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    query:"getSalesByCategory"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}


export const getComercialFacturation = async () => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    query:"getComercialFacturation"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}


export const gettotalSellerOfferByStatus = async (id:number) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    idstatus:id,
    query:"getOffersCTPbyStatus"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const obtenerDatosDeVentas = async () => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    query:"obtenerDatosDeVentas"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const obtenerDatosDeVentasComercial = async (id:number) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    id_comercial:id,
    query:"obtenerDatosDeVentasComercial"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const obtenerDatosDeVentasCustomer = async (nmc:string) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    nmc_code :nmc,
    query:"obtenerDatosDeVentasCustomer"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const obtenerVentasPorMesYAnio = async () => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    query:"obtenerVentasPorMesYAnio"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const obtenerVentasPorMesYAnioCustomer = async (nmc:string) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    nmc_code:nmc,
    query:"obtenerVentasPorMesYAnioCustomer"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const obtenerVentasPorMesYAnioComercial = async (id:number) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    id_comercial:id,
    query:"obtenerVentasPorMesYAnioComercial"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const obtenerVentasTotalesPorAnio = async () => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    query:"obtenerVentasTotalesPorAnio"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const obtenerVentasPorProvincia = async () => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    query:"obtenerVentasPorProvincia"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const obtenerVentasPorRegion = async (zone:string,year:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    zone: zone,
    year:year,
    query:"obtenerVentasPorRegion"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const obtenerVentasPorMesYAnio2 = async () => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    query:"obtenerVentasPorMesYAnio2"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const obtenerVentasPorMesYAnioByCustomer = async (nmc:string) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    nmc_code:nmc,
    query:"obtenerVentasPorMesYAnioByCustomer"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const obtenerVentasPorMesYAnioByComercial = async (id:number) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    id_comercial:id,
    query:"obtenerVentasPorMesYAnioByComercial"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const obtenerDatosPareto = async () => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    query:"obtenerDatosPareto"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const obtenerVentasVendedoresPorMesYAnio = async () => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    query:"obtenerVentasVendedoresPorMesYAnio"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}


export const obtenerVentasPorProductoYAnioPorCliente = async (nmc_code:string) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    customer_code: nmc_code,
    query:"obtenerVentasPorProductoYAnioPorCliente"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}


export const obtenerVentasPorProductoYAnioPorComercial = async (id:number,limit:number,year:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    id_comercial: id,
    limit: limit,
    year:year,
    query:"obtenerVentasPorProductoYAnioPorComercial"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const obtenerVendedoresPorProductoYAnio = async (id:string,year:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    product_code: id,
    year:year,
    query:"obtenerVendedoresPorProductoYAnio"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const calcularRendimientoProductoPorTipoCliente = async (id:string,year:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    product_code: id,
    year:year,
    query:"calcularRendimientoProductoPorTipoCliente"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const obtenerDetallesClientesPorProducto = async (id:string,year:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    product_code: id,
    year:year,
    query:"obtenerDetallesClientesPorProducto"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}



export const obtenerVentasProductosByAnio = async (year:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    year:year,
    query:"obtenerVentasProductosByAnio"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}




export const detalleventasporcliente = async (nmc_code:string) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    customer_code: nmc_code,
    query:"detalleventasporcliente"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const obtenerDatosParaGraficoRosaDeLosVientos = async (year:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    year:year,
    query:"obtenerDatosParaGraficoRosaDeLosVientos"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}


export const obtenerCumplimientoMetaAnual = async (year:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    year:year,
    query:"obtenerCumplimientoMetaAnual"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const obtenerVentas80y20PorMesYAnio = async (year:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    year:year,
    query:"obtenerVentas80y20PorMesYAnio"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const obtenerDatosParaGraficoDeBurbujas = async (year:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    year: year,
    query:"obtenerDatosParaGraficoDeBurbujas"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const obtenerRelacionesProyectoCliente = async () => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    query:"obtenerRelacionesProyectoCliente"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const obtenerRelacionesProductoCliente = async (year:any,month:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    year: year,
    month:month,
    query:"obtenerRelacionesProductoCliente"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const getsellers = async (year:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    year:year,
    query:"getsellers"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const getproducts = async () => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    query:"getproducts"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}

export const obtenerVentasPorMaterialidad = async () => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    anio: 2024,
    query:"obtenerVentasPorMaterialidad"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}


export const calcularRendimientoVendedor = async (id:number,year:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    enterprise: 1,
    id_comercial: id,
    year:year,
    query:"calcularRendimientoVendedor"
  }
  try {
    const response = await axios.post(config.SERVER_CHART, data);
   
      return response;
  
  } catch (e) {
    console.log(e);
  }
  
}
